<template>
  <label
    class="file-upload"
    :class="{
      'file-upload--disabled': disabled,
      'file-upload--highlight': highlight && !disabled,
    }"
    @dragenter="dragOver"
    @dragleave="dragLeave"
    @dragover="dragOver"
    @drop="drop"
  >
    <span class="file-upload__list" v-if="files.length && !drag && !highlight">
      <span class="file-upload__item" v-for="(file, i) in files" :key="i">
        <a :href="file.url" class="file-upload__item-link" target="_blank" download>
          <FileIconComponent :ext="file.type" />
          <span>{{ file.origin_name }}</span>
        </a>
        <a @click.prevent="remove(i)" v-if="canDelete" class="file-upload__item-remove">
          <CloseIcon />
        </a>
      </span>
    </span>
    <span class="file-upload__placeholder" v-else>
      <span class="file-upload__btn">Выбрать файлы</span>
      <span>или перетащите файлы сюда</span>
    </span>
    <input type="file" ref="input" v-show="false" @change="change" multiple :disabled="loading || disabled" />
  </label>
</template>

<script>
import FileIconComponent from "@/components/inputs/FileIconComponent";
import CloseIcon from "../svg/CloseIcon.vue";

export default {
  name: "FileUploadComponent",
  props: {
    uploaded: Array,
    disabled: Boolean,
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      drag: false,
      highlight: false,
      over: false,
      loading: false,
      files: [],
      dragTimeout: null,
      highlightTimeout: null,
    };
  },
  mounted() {
    this.files = this.uploaded;
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.drag = true;
    });
    document.addEventListener("dragleave", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfDrag(false);
    });
  },
  methods: {
    debouncedDelayedSetOfDrag(value) {
      clearTimeout(this.dragTimeout);
      this.dragTimeout = setTimeout(() => {
        this.drag = value;
      }, 200);
    },
    debouncedDelayedSetOfHighlight(value) {
      clearTimeout(this.highlightTimeout);
      this.highlightTimeout = setTimeout(() => {
        this.highlight = value;
      }, 200);
    },
    dragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfHighlight(false);
    },
    dragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      this.highlight = true;
    },
    drop(event) {
      event.preventDefault();
      const dt = event.dataTransfer;
      if (dt) {
        this.loading = true;
        this.debouncedDelayedSetOfHighlight(false);
        this.debouncedDelayedSetOfDrag(false);
        this.upload(dt.files);
      }
    },
    change(event) {
      if (!this.loading && !this.disabled) {
        this.loading = true;
        if (event.target.files.length) {
          this.upload(event.target.files);
        }
      }
    },
    remove(i) {
      if (confirm("Вы уверены?")) {
        this.files.splice(i, 1);
      }
    },
    upload(files) {
      this.$store
        .dispatch("UPLOAD", {
          context: this,
          variables: {
            files: files,
          },
        })
        .then(({ data }) => {
          if (data.UploadDocument && data.UploadDocument.length) {
            this.files.push(...data.UploadDocument);
          }
          this.loading = false;
          this.$emit("change", this.files);
        });
    },
  },
  components: {
    CloseIcon,
    // LoaderIcon,
    FileIconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.file-upload {
  display flex
  align-items center
  justify-content center
  flex-flow row wrap
  width 100%
  min-height 200px
  background var(--main_table_header)
  border: 1px dashed var(--main_blue_bright2);
  border-radius var(--radius)
  padding 38px 25px
  gap 24px
  transition border-color .3s
  cursor pointer

  &--highlight
  &:hover {
    padding 37px 24px
    border-width 2px
    border-color var(--main_blue);
  }

  &--highlight &__btn
  &:hover &__btn {
    background var(--main_white)
    border-color var(--main_blue)
    color var(--main_blue)
  }

  &:active &__btn {
    transform scale(0.97)
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__loading {
    absolute left top
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    background var(--main_dark-t4);
    border-radius var(--radius)

    .icon {
      width 40px
      height 40px
      svg(var(--main_white))
    }
  }

  &__item {
    width 92px
    position relative

    &-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      background: var(--error_red);
      border-radius: var(--radius);
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      transform: translate(100%, -100%);
      border none

      .icon {
        width 16px
        height 16px
        display flex
        align-items center
        justify-content center

        svg {
          svg(var(--main_white))
        }
      }
    }

    &-link {
      display flex
      flex-direction column
      justify-content flex-start
      align-items center
      font-weight: normal;
      font-size: 0.75em;
      line-height: normal;
      text-align: center;
      letter-spacing: var(--letter_spacing_small);
      color: var(--main_gray);
      white-space: pre-wrap;
      word-wrap: break-word;
      gap 12px

      span {
        width 100%
      }

      .icon {
        height 60px
        max-width 60px
      }
    }
  }

  &__placeholder {
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 8px
    font-weight: normal;
    font-size: 0.8750em;
    line-height: 20px;
    text-align: center;
    letter-spacing: var(--letter_spacing);
    color: var(--main_default_dark);
    user-select none
  }

  &__btn {
    background: var(--main_upload_btn);
    border-radius: var(--button_small_radius);
    border: 1px solid var(--main_upload_btn);
    padding 8px 12px;
    font-size: 0.75em;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: var(--main_gray);
    transition .3s
  }
}
</style>
